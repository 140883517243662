import {
  SequenceCategoriesRapport,
  SequenceCreditsRapport,
  SequenceProductRapportDto,
  SequencePaymentsRapport,
  Sequence,
  QuerySequenceCategoriesRapportArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { SEQUENCE_FIELDS } from "@/graphql/sequence/sequence";
import { productParams } from "../utils/product";

type SequencePaymentsPrint = Partial<SequencePaymentsRapport> & {
  specialCreditPayments?: number;
  creditPaymentSpecie?: number;
};
export type SequenceRapportData = {
  sequenceCreditsRapport: SequenceCreditsRapport[];
  sequenceCategoriesRapport: SequenceCategoriesRapport[];
  sequenceProductsRapport: SequenceProductRapportDto[];
  sequencePaymentsRapport: SequencePaymentsPrint;
  sequenceExpenseRapport: number;
  sequenceTicketsBackRapport: SequenceProductRapportDto[];
  sequenceGet?: Sequence;
  productDiscount?: number;
};

const SEQUENCE_RAPPORT = (withSequence = false) => gql`
  query sequenceRapport($sequenceId: String!) {
    sequenceCategoriesRapport(sequenceId: $sequenceId) {
      category
      inclTax
    }
    sequenceProductsRapport(sequenceId: $sequenceId) {
      product
      quantity
      inclTax
    }
    sequenceCreditsRapport(sequenceId: $sequenceId) {
      amount
      customerName
      received
      change
      discount
    }
    sequencePaymentsRapport(sequenceId: $sequenceId) {
      total
      payed
      discount
      credits
      creditPayments
      creditPaymentSpecies
      offer
      paymentsByTypes{ type, amount }
      specialPayments {
        customer
        amount
        sold
      }
    }
    sequenceExpenseRapport(sequenceId: $sequenceId)
    sequenceTicketsBackRapport(sequenceId: $sequenceId) {
      product
      quantity
      inclTax
    }
    ${
      withSequence
        ? `sequenceGet(sequenceId: $sequenceId){
        ${SEQUENCE_FIELDS}
        terminal {
          id
          name
          description
        }
        opener {
          id
          name
        }
        closer {
          id
          name
        }
        }`
        : ""
    }
  }
`;

export const useSequenceRapport = () => {
  const { loading, result, load, onResult } = useLazyQuery<
    SequenceRapportData,
    QuerySequenceCategoriesRapportArgs
  >(SEQUENCE_RAPPORT(), { sequenceId: "" }, { fetchPolicy: "no-cache" });

  function loadRapport(sequenceId: string) {
    load(SEQUENCE_RAPPORT(), { sequenceId }, { fetchPolicy: "no-cache" });
  }
  function loadWithSequenceRapport(sequenceId: string) {
    load(SEQUENCE_RAPPORT(true), { sequenceId }, { fetchPolicy: "no-cache" });
  }

  return {
    loading,
    loadWithSequenceRapport,
    result,
    loadRapport,
    onResult,
  };
};

// Utilities functions
export const sequenceProductsRapport = (
  rapport: SequenceProductRapportDto[]
) => {
  return rapport.reduce(
    (cum, cur) => {
      const { name, discount } = productParams({ name: cur.product });
      if (discount) {
        cum.productDiscount += cur.inclTax * cur.quantity;
      } else {
        cum.list.push({ ...cur, name });
      }
      return cum;
    },
    { list: [], productDiscount: 0 }
  );
};

export const categoriesRapport = (rapport: SequenceCategoriesRapport[]) => {
  return rapport.reduce((cum, cur) => {
    if (!cur.category.includes("P10COUNT")) {
      cum.push(cur);
    }
    return cum;
  }, []);
};
